<template>
  <div class="gwp-progress-bar">
    <div class="gwp-progress-bar__bar-bg" :style="{ backgroundColor: bgColor }">
      <div
        class="gwp-progress-bar__bar"
        :style="{ backgroundColor: barColor, width: progressCss }"
      />
    </div>
    <p class="gwp-progress-bar__copy">
      {{ currentCopy }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    threshold: {
      type: Number,
      required: true,
    },
    bgColor: {
      type: String,
      default: "#ccc",
    },
    barColor: {
      type: String,
      default: "#000",
    },
    copyEmpty: {
      type: String,
      default: "GWP empty state copy",
    },
    copyApproaching: {
      type: String,
      default: "GWP approaching state copy",
    },
    copyCongrats: {
      type: String,
      default: "GWP congrats state copy",
    },
    copyAddedGift: {
      type: String,
      default: "GWP in cart state copy",
    },
    copySubError: {
      type: String,
      default: "GWP doesn't work with Subscriptions",
    },
  },
  data: () => ({
    price: 0,
    isLoading: false,
    subscriptionInCart: false,
  }),
  computed: {
    progress() {
      return (this.price / 100 / this.threshold) * 100;
    },
    progressCss() {
      return `${this.progress}%`;
    },
    gwpIsInCart() {
      return this.$store.state.gwp?.isInCart;
    },
    currentCopy() {
      if (this.subscriptionInCart) {
        return this.copySubError;
      } else if (this.progress === 0) {
        return this.copyEmpty;
      } else if (this.progress < 100) {
        const spendMore = this.threshold - this.price / 100;
        if (spendMore % 1 === 0) {
          return this.copyApproaching.replace("[XX]", spendMore);
        } else {
          return this.copyApproaching.replace("[XX]", spendMore.toFixed(2));
        }
      } else if (this.gwpIsInCart) {
        return this.copyAddedGift;
      } else {
        return this.copyCongrats;
      }
    },
  },
  watch: {
    progress(newVal) {
      if (newVal >= 100 && !this.subscriptionInCart) {
        this.$store.dispatch("gwp/show");
      } else {
        this.$store.dispatch("gwp/hide");
      }
    },
  },
  created() {
    // this ONLY runs in atlantic js for refreshing the cart method
    // this.refreshComponent()

    if (window.__bocces) {
      if (window.__bocces.vueMethods) {
        window.__bocces.vueMethods.updateGWPPrice = this.refreshComponent;
      } else if (!window.__bocces.vueMethods) {
        window.__bocces.vueMethods = {
          updateGwpBar: this.refreshComponent,
        };
      }
    }
  },
  methods: {
    refreshComponent() {
      // console.log('refreshing component for GWP')
      const cartMessage = document.querySelector("#gwp-cart-message");
      const cartMessageFreeGiftRemoval = document.querySelector(
        "#gwp-cart-message-sub-removal"
      );
      const $ = window.$;
      if ($ && !this.isLoading) {
        $.get("/cart.json", (data) => {
          this.isLoading = false;
          let isInCart = false;
          let gwpLineItem = null;
          this.subscriptionInCart = false;
          data.items?.forEach((item) => {
            if (item?.properties?._gwp === "true") {
              isInCart = true;
              gwpLineItem = item;
            }
            if (
              item?.properties?.shipping_interval_frequency ||
              item?.properties?.shipping_interval_unit_type
            ) {
              this.subscriptionInCart = true;
            }
          });
          this.$store.dispatch("gwp/setIsInCart", isInCart);

          if (this.subscriptionInCart) {
            this.$store.dispatch("gwp/setSubInCart", true);
          } else {
            this.$store.dispatch("gwp/setSubInCart", false);
          }

          if (cartMessageFreeGiftRemoval && this.subscriptionInCart) {
            cartMessageFreeGiftRemoval.classList.remove("hidden");
          }

          // use the gwp product to to subtract from total
          // check new total is over threshold
          // if not remove item from cart
          // if it is ... do nothing
          let newTotal = null;
          if (gwpLineItem) {
            if (cartMessage) {
              cartMessage.classList.remove("hidden");
            }

            // Logic added on 30 April 2024 previously were only -> newTotal = data.total_price - gwpLineItem.price;
            if (gwpLineItem.final_price == gwpLineItem.price) {
              newTotal = data.total_price - gwpLineItem.price;
            } else {
              newTotal = data.total_price - gwpLineItem.final_price;
            }
            //=====================================================================================================

            const isStillOverThreshold = this.threshold * 100 <= newTotal;
            const updates = {
              [gwpLineItem.key]: 0,
            };
            if (!isStillOverThreshold) {
              $.ajax({
                type: "POST",
                url: "/cart/update.js",
                data: {
                  updates,
                },
                success: () => {
                  // refresh cart
                  window.__bocces?.miniCart?.refresh();
                  // remove localstorage
                  window.localStorage.removeItem("gwpAdded");
                  if (cartMessage) {
                    cartMessage.classList.add("hidden");
                  }
                },
              });
            } else if (this.subscriptionInCart) {
              $.ajax({
                type: "POST",
                url: "/cart/update.js",
                data: {
                  updates,
                },
                success: () => {
                  // refresh cart
                  window.__bocces?.miniCart?.refresh();
                  // remove localstorage
                  window.localStorage.removeItem("gwpAdded");

                  if (cartMessage) {
                    cartMessage.classList.add("hidden");
                  }
                  if (cartMessageFreeGiftRemoval) {
                    console.log("revealing the removal message");
                    cartMessageFreeGiftRemoval.classList.remove("hidden");
                  }
                },
              });
            }
          } else if (
            this.progress >= 100 &&
            !gwpLineItem &&
            !this.subscriptionInCart
          ) {
            // TODO:  add or remove localstorage
            this.$store.dispatch("gwp/show");
          }

          if (!newTotal) {
            this.price = data.total_price;
          } else {
            this.price = newTotal;
          }
        });
      }
    },
  },
};
</script>

<style>
.gwp-progress-bar {
  text-align: center;
  font-size: 13px;
  padding-top: 13px;
}

.gwp-progress-bar__bar-bg {
  border-radius: 2px;
  overflow: hidden;
}

.gwp-progress-bar__bar {
  height: 4px;
}

.gwp-progress-bar__copy {
  margin-top: 10px;
}
</style>
